<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <span class="modal-card-title">Products for `{{batchDestination.Name }}`</span>
        <button class="delete" aria-label="close" v-on:click="Close"></button>
      </header>
      <section class="modal-card-body">
        <p>
          <span>
            There is currently
            <b>{{ EnabledProducts(batchDestination).length }} enabled</b> products
            and
            <b>{{ DisabledProducts(batchDestination).length }} disabled</b>
            products on this batch destination.
          </span>
          <router-link :to="'/product-config?destinationId=' + batchDestination.Id">Manage products</router-link>
        </p>

        <table class="table is-fullwidth" v-if="batchDestination.ProductsLinked.length > 0">
          <thead>
            <tr>
              <th>Product Code</th>
              <th>Publisher Code</th>
              <th>Name</th>
              <th class="has-text-centered">Enabled</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(p, index) in batchDestination.ProductsLinked" :key="index">
              <td>{{p.FourLetterCode}}</td>
              <td>{{p.FiveLetterCode}}</td>
              <td>{{p.Name}}</td>
              <td class="has-text-centered">
                <span
                  v-on:click="ConfirmToggleEnabled(p)"
                  v-if="p.Enabled"
                  class="tag is-success cur"
                >Yes</span>
                <span v-on:click="ConfirmToggleEnabled(p)" v-else class="tag is-danger cur">No</span>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-else>No Products linked to this destination</p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "batchDestinationProducts",
  props: {
    batchDestination: Object
  },
  data() {
    return {
      APIUrl: process.env.VUE_APP_APIURL
    };
  },
  methods: {
    Close() {
      //emit close!
      this.$emit("close");
    },
    ConfirmToggleEnabled(p) {
      var isEnabled = p.Enabled;

      var msg = p.Enabled
        ? "Are you sure you want to <b>DISABLE</b> this '<b>" +
          p.Name +
          "</b>', no fulfilments will be sent"
        : "Are you sure you want to <b>ENABLE</b> this '<b>" +
          p.Name +
          "</b>', it will start sending fulfilments within the assoicated destination";

      var title = (p.Enabled ? "Disable " : "Enable ") + p.Name + "?";

      this.$dialog.confirm({
        title: title,
        message: msg,
        _type: "is-danger",
        get type() {
          return this._type;
        },
        set type(value) {
          this._type = value;
        },
        onConfirm: () => this.ToggleEnabled(p)
      });
    },
    ToggleEnabled(p) {
      var newState = !p.Enabled;
      var url = this.APIUrl + "/product/" + p.Id + "/enabled/" + newState;
      this.axios.put(url).then(response => {
        p.Enabled = newState;
        var state = newState ? "ENABLED" : "DISABLED";
        this.$toast.open(
          "Successfully updated product <b>'" +
            p.Name +
            "'</b> state to: <b>'" +
            state +
            "'</b>"
        );
      });
    },
    EnabledProducts(d) {
      return d.ProductsLinked.filter(function(p) {
        return p.Enabled;
      });
    },
    DisabledProducts(d) {
      return d.ProductsLinked.filter(function(p) {
        return !p.Enabled;
      });
    }
  }
};
</script>
